import React, { useState } from "react";
import { useNavigate } from "react-router";
import Pagination from "../../../../components/pagination/Pagination";
import toast from "react-hot-toast";
import { api } from "../../../../api/admin-management";
import { useQuery } from "react-query";
import { ScaleLoader } from "react-spinners";
import Search from "../../../../components/Inputs/Search";
import ConfirmModal from "../../../../components/Modal/ConfirmModal";
import moment from "moment";

const RolesAndPrivileges = () => {
  const navigate = useNavigate();

  const user = JSON.parse(window.sessionStorage.getItem("user"));
  const orgId = user?.org?.id;
  const accountType = user?.role?.slug;
  const isClientAdmin = accountType === "client-admin";

  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);

  console.log(selectedRole, "role")
  const {
    data: rolesData,
    isLoading,
    refetch,
  } = useQuery(
    ["all-roles", search, perPage, currentPage],
    async () => {
      const response = await api.getAllRoles({
        ...(isClientAdmin ? { orgId } : {}),
        search,
        perPage,
        currentPage,
      });
     
      return response.data;
    },
    {
      cacheTime: 100,
      staleTime: 100,
    },
    {
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );

 
  const deleteRole = async () => {
    
    if (!selectedRole) return;
    try {
      const response = await api.deleteRole({
        roleId: selectedRole.id,
      });
      setDeleteModal(false);
      toast.success(response.message);
      refetch(); // Refresh the data after deletion
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  return (
    <div>
      <div className="p-4 md:p-6">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-semibold text-[#091F46] mb-3">
            Roles & Privileges
          </h3>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={() => {
                navigate("/admin/roles-and-privileges/new-role");
              }}
              className="p-3 bg-customBlue text-white text-xs rounded-md"
            >
              New Role
            </button>
          </div>
        </div>

        <div className="p-4 md:p-6 overflow-auto ">
          <div className="flex justify-between mb-3">
            <h3 className="text-lg font-semibold text-[#091F46] ">Admins</h3>
            <div className=" flex gap-6 justify-end">
              <Search
                onChange={setSearch}
                placeholder={"Search ..."}
                className={"w-fit"}
              />
            </div>
          </div>

          <div className="bg-white p-3 rounded-lg shadow-sm">
            {isLoading ? (
              <div className="mt-10 flex justify-center items-center">
                <ScaleLoader color="#FF9100" />
              </div>
            ) : (
              <div className="mt-4 ">
                <div className="">
                  <table className="table-container">
                    <thead className="table-header">
                      <tr>
                        <th className="table-head text-left">#</th>
                        <th className="table-head text-left">Role Name</th>
                        {/* <th className="table-head">DESCRIPTION</th> */}
                        <th className="table-head text-left">Role privileges</th>
                        <th className="table-head text-left">Assigned To</th>

                        <th className="table-head text-left">Last Updated</th>
                      </tr>
                    </thead>

                    <tbody className="table-body text-xs">
                      {rolesData?.length > 0 ? (
                        rolesData.map((role, index) => (
                          <tr key={index}>
                            <td className="table-data">{index + 1}</td>
                            <td className="table-data text-left">
                              {role.name}
                            </td>
                            <td className="table-data text-left">
                              {role.rolePrivilege.length}
                            </td>
                            <td className="table-data text-left">
                              {role.numberOfUsers}
                            </td>
                            <td className="table-data ">
                              {moment(role.updatedAt).format(
                                "YYYY/MM/DD, h:mm a"
                              )}
                            </td>

                            <td className="table-data flex gap-4 justify-center px-2">
                              <i
                                className="bi bi-pencil-fill cursor-pointer"
                                onClick={() => {
                                  sessionStorage.setItem('selectedRole', JSON.stringify(role));
                                  navigate('/admin/roles-and-privileges/edit-role', {state: {role}})
                                  setSelectedRole(role);
                                }}
                              ></i>
                              <i
                                className="bi bi-trash3-fill  text-red-500 cursor-pointer"
                                onClick={() => {
                                  setSelectedRole(role);
                                  setDeleteModal(true);
                                }}
                              ></i>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td className="table-data" colSpan="12">
                            <p className="text-center">No Data Available</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                    {deleteModal && (
                      <ConfirmModal
                        title="Delete role"
                        description={`Are you sure you want to delete ‘‘${selectedRole?.name}”?`}
                        onYes={deleteRole}
                        onClose={() => setDeleteModal(false)}
                      />
                    )}
                  </table>
                </div>
              </div>
            )}
            <section className={`mt-1 px-3 rouded-lg bg-white`}>
              <Pagination
                data={rolesData?.meta}
                perPage={perPage}
                pageNo={currentPage}
                setPageNo={setCurrentPage}
                setPerPage={setPerPage}
              />
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RolesAndPrivileges;
