import React, { useState } from "react";
import { useQuery } from "react-query";
import { api } from "../../../../../api/zoom";
import toast from "react-hot-toast";
import Search from "../../../../../components/Inputs/Search";
import { ScaleLoader } from "react-spinners";
import moment from "moment/moment";
import { Link, useNavigate } from "react-router-dom";
import ConfirmModal from "../components/ConfirmModal";
import VerticalMenu from "../../../../../components/dropdown/VerticalMenu";
import MenuItem from "../../../../../components/dropdown/MenuItem";
import Pagination from "../../../../../components/pagination/Pagination";

const ActiveMeetings = () => {
  const navigate = useNavigate();
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const orgId = JSON.parse(window.sessionStorage.getItem("user"))?.org?.id;

  const {
    data: meetings,
    isLoading,
    refetch,
  } = useQuery(
    ["all-meetings", search, perPage, currentPage],
    async () => {
      const response = await api.getAllMeetings({
        orgId,
        search,
        perPage,
        currentPage,
      });
      return response.data;
    },
    {
      cacheTime: 100,
      staleTime: 100,
    },
    {
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );

  const deleteMeeting = async () => {
    if (!selectedMeeting) return;
    try {
      const response = await api.deleteMeeting({
        orgId,
        meetingId: selectedMeeting.id,
      });
      setDeleteModal(false);
      toast.success(response.message);
      refetch(); // Refresh the data after deletion
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  // Copy Meeting URL to Clipboard
  const handleCopyUrl = (url) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        toast.success("Meeting URL copied to clipboard!");
      })
      .catch(() => {
        toast.error("Failed to copy the meeting URL.");
      });
  };

  return (
    <div className="p-4 md:p-6 ">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-semibold text-[#091F46] mb-3">
          Active Meetings
        </h3>
        <div className="flex justify-end">
          <button
            type="button"
            onClick={() => {
              navigate("/admin/zoom/create-meeting");
            }}
            className="p-3 bg-customBlue text-white text-xs rounded-md"
          >
            Create new meeting
          </button>
        </div>
      </div>
      <div className="mt-10">
        <div className=" flex justify-end">
          <Search
           onChange={setSearch}
            placeholder={"Search ..."}
            className={"w-fit"}
          />
        </div>
      </div>
      <div className="bg-white p-3 rounded-lg shadow">
        {isLoading ? (
          <div className="mt-10 flex justify-center items-center">
            <ScaleLoader color="#FF9100" />
          </div>
        ) : (
          <div className="mt-3">
            <div className="">
              <table className="table-container">
                <thead className="table-header">
                  <tr>
                    <th className="table-head">ID</th>
                    <th className="table-head text-left">Meeting Name</th>
                    {/* <th className="table-head ">Description</th> */}
                    <th className="table-head">Meeting Token</th>
                    <th className="table-head">Start Time</th>
                    <th className="table-head">Duration</th>
                    {/* <th className="table-head">Time Zone</th> */}
                    <th className="table-head">Password</th>
                    <th className="table-head">Date</th>
                    <th className="table-head">Status</th>
                    <th className="table-head">Action</th>
                  </tr>
                </thead>

                <tbody className="table-body text-xs">
                  {meetings?.length > 0 ? (
                    meetings.map((meeting, index) => (
                      <tr key={index}>
                        <td className="table-data">{index + 1}</td>
                        <td className="table-data ">{meeting.topic}</td>
                        <td className="table-data ">{meeting.zoomMeetingId}</td>
                        <td className="table-data ">{meeting.startTime}</td>
                        <td className="table-data ">{meeting.duration}</td>
                        {/* <td className="table-data ">{meeting.timeZone}</td> */}
                        <td className="table-data ">{meeting.password}</td>
                        <td className="table-data ">
                          {moment(meeting.updatedAt).format(
                            "YYYY/MM/DD, h:mm a"
                          )}
                        </td>
                        <td className="table-data">
                          <span
                            className={`px-2 py-1 rounded-full  text-xs font-semibold ${
                              meeting.status === "null"
                                ? "bg-[#FEECEE] text-[#EB3D4D]"
                                : "bg-[#E9FAF7] text-[#1A9882]"
                            }`}
                          >
                            {meeting.status}
                          </span>
                        </td>
                        <td className="table-data text-center">
                          <VerticalMenu>
                            <div className="w-fit flex flex-col gap-2">
                              <Link
                                to={meeting.joinUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <button className="p-2 bg-customBlue text-white text-xs rounded-md mt-2">
                                  + Start Meeting
                                </button>
                              </Link>
                              <MenuItem
                                icon={
                                  <i className="bi bi-pencil-fill cursor-pointer"></i>
                                }
                                label="Edit"
                                onClick={() => {
                                  navigate(
                                    `/admin/zoom/edit-meeting/${meeting.id}`
                                  );
                                }}
                              />
                              <MenuItem
                                icon={
                                  <i className="bi bi-copy font-bold cursor-pointer"></i>
                                }
                                label="Copy Link"
                                onClick={() => handleCopyUrl(meeting.joinUrl)}
                              />
                              <MenuItem
                                icon={
                                  <i className="bi bi-trash3-fill  text-red-500 cursor-pointer"></i>
                                }
                                label="Delete"
                                onClick={() => {
                                  setSelectedMeeting(meeting);
                                  setDeleteModal(true);
                                }}
                              />
                            </div>
                          </VerticalMenu>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="table-data" colSpan="12">
                        <p className="text-center">No Data Available</p>
                      </td>
                    </tr>
                  )}
                </tbody>
                {deleteModal && (
                  <ConfirmModal
                    title="Delete Meeting"
                    description={`Are you sure you want to delete ‘‘${selectedMeeting?.topic}”?`}
                    onYes={deleteMeeting}
                    onClose={() => setDeleteModal(false)}
                  />
                )}
              </table>
            </div>
          </div>
        )}
        <section className={`mt-1 px-3 rouded-lg bg-white`}>
          <Pagination
            data={meetings?.meta}
            perPage={perPage}
            pageNo={currentPage}
            setPageNo={setCurrentPage}
            setPerPage={setPerPage}
          />
        </section>
      </div>
    </div>
  );
};

export default ActiveMeetings;
