import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    const token = JSON.parse(sessionStorage.getItem("user"))?.token;
    if (token) {
      navigate("/admin/dashboard");
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    const timeout = setTimeout(handleRedirect, 3000);
    return () => clearTimeout(timeout); // Cleanup timeout
  }, [navigate]);

  return (
    <main className="flex flex-col items-center justify-center h-screen bg-gray-50 text-gray-900">
      <h1 className="text-6xl font-bold mb-4 text-red-600 animate-pulse">
        404 - Page Not Found
      </h1>
      <p className="text-lg mb-2 text-gray-700 animate-fade-in">
        The page you are trying to visit does not exist.
      </p>
      <p className="text-sm text-gray-500 animate-fade-in-delay">
        Redirecting you shortly...
      </p>
      <button
        className="mt-6 px-6 py-2 text-white bg-customBlue rounded-md shadow-md hover:bg-customBlue/60 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all duration-200"
        onClick={handleRedirect}
      >
        Go to Home
      </button>
    </main>
  );
};

export default NotFound;
