import React from 'react'
import ReactPlayer from "react-player";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import CenteredModal from '../../../../../components/Modal/CenteredModal';
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ViewContentModal = ({selectedContent, contentType, filePath, setOpenViewContentModal, openViewContentModal}) => {
console.log(contentType, "content type")
console.log(filePath, "file path")
console.log(selectedContent, "selected content")
    // const renderContent = () => {
    //     const fileExtension = filePath?.split(".").pop().toLowerCase();
    //     // Handle different file types
    //     switch (contentType?.value) {
    //       case "image":
    //         return <img src={filePath} alt="Content Preview" className="w-full h-auto" />;
    //       case "audio":
    //         return <ReactPlayer url={filePath} controls playing width="100%" height="50px" />;
    //       case "video":
    //         return <ReactPlayer url={filePath} controls playing width="100%" height="400px" />;
    //       case "pdf":
    //         return (
    //           <Document file={filePath}>
    //             <Page pageNumber={1} />
    //           </Document>
    //         );
    //       case "ppt":
    //         return (
    //           <iframe
    //             src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(filePath)}`}
    //             className="w-full h-[400px]"
    //             title="PPT Viewer"
    //           />
    //         );
    //       case "csv":
    //       case "spreadsheet":
    //         return (
    //           <iframe
    //             src={`https://docs.google.com/gview?url=${encodeURIComponent(filePath)}&embedded=true`}
    //             className="w-full h-[400px]"
    //             title="Spreadsheet Viewer"
    //           />
    //         );
    //       default:
    //         return <p>Unsupported content type for preview.</p>;
    //     }
    //   };
      
    const renderContent = () => {
      const fileExtension = filePath?.split(".").pop().toLowerCase();
    
      switch (contentType) {
        case "image":
          return (
            <img 
              src={filePath} 
              alt="Content Preview" 
              className="w-full h-auto" 
              onError={() => console.error("Failed to load image.")}
            />
          );
        case "audio":
          return (
            <ReactPlayer 
              url={filePath} 
              controls 
              playing 
              width="100%" 
              height="50px" 
              onError={(e) => console.error("Error playing audio:", e)}
            />
          );
        case "video":
          return (
            <ReactPlayer 
              url={filePath} 
              controls 
              playing 
              width="100%" 
              height="400px" 
              onError={(e) => console.error("Error playing video:", e)}
            />
          );
          
          case "pdf":
            return (
              <Document 
                file={filePath} 
                onLoadError={(error) => console.error("Error loading PDF:", error)}
              >
                <Page pageNumber={1} />
              </Document>
            );
        case "ppt":
          return (
            <iframe
              src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(filePath)}`}
              className="w-full h-[400px]"
              title="PPT Viewer"
            />
          );
        case "csv":
        case "spreadsheet":
          return (
            <iframe
              src={`https://docs.google.com/gview?url=${encodeURIComponent(filePath)}&embedded=true`}
              className="w-full h-[400px]"
              title="Spreadsheet Viewer"
            />
          );
        default:
          return <p>Unsupported content type for preview.</p>;
      }
    };
    
  return (
    <CenteredModal
    title={"View Content"}
    hasHeader={true}
    open={openViewContentModal}
    setOpen={setOpenViewContentModal}
    width={"w-[800px]"}
  >
    <div className="content-preview">{renderContent()}</div>
  
   </CenteredModal>
  )
}

export default ViewContentModal