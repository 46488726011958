import React from 'react'
import { Routes, Route } from "react-router";
import ContentLibrary from './pages/ContentLibrary';



const ContentRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ContentLibrary/>} />     
      </Routes>
  )
}

export default ContentRoutes