import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ViewCohort from './viewCohort/ViewCohort'
import CreateCohort from './createCohort/CreateCohort'
import EditCohort from './editCohort/EditCohort'
import ViewLearners from './viewCohort/ViewLearners'
import LearnerProfile from './viewCohort/LearnerProfile'


const Cohorts: React.FC = () : React.ReactElement => {
  return (
    <div>
        <Routes>
            <Route path="/" element={<ViewCohort />} />
            <Route path="/create" element={<CreateCohort />} />
            <Route path="/edit/:id" element={<EditCohort />} />
            <Route path="/view/:id" element={<ViewLearners />} />
            <Route path="/learner/:id" element={<LearnerProfile />} />
        </Routes>
    </div>
  )
}

export default Cohorts