import React from 'react'
import wavingHand from "../../../../assets/twemoji_waving-hand.png"
import userImg from "../../../../assets/profile-add.png"
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useNavigate } from 'react-router';

const Navbar = () => {

    function greetTime() {
        const today = new Date();
        const hourOfDay = today.getHours(); //The current hour of today
        if (hourOfDay > 11 && hourOfDay <= 16) {
          return "afternoon";
        } else if (hourOfDay > 16) {
          return "evening";
        } else {
          return "morning";
        }
      }

 const org: any = JSON.parse(sessionStorage.getItem('user')!) 
 const photo = sessionStorage.getItem('profile-information')
 const navigate = useNavigate()
      
  return (
    <div className='flex justify-between border-b pt-2 border-slate-300 pb-3'>
        <h5 className='text-sm capitalize font-bold flex items-center'>
            Good {greetTime()},  {org?.name} &nbsp;
            <img src={wavingHand} alt="hands" />
        </h5>
        <div>
        <DropdownMenu.Root>
         <DropdownMenu.Trigger asChild>
        <button className="text-sm items-center font-semibold flex" aria-label="Customise options">
             {
                photo  ||
                org?.photo ?
                <img 
                 src={ photo ? JSON.parse(photo)?.photo : org?.photo}
                  className='w-[35px] h-[35px] rounded-full object-cover'
                 alt="user profile image" />
                :
                <span className='flex items-center rounded-full justify-center  w-[30px] bg-slate-200 h-[30px]'>
                  <img src={userImg} alt="profile" className='w-[25px] h-[25px]' />
                </span>
             }
             <span className='pl-1'>
                {org?.name}
            </span>
            <i className='bi bi-chevron-down pl-1' />
        </button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content className="DropdownMenuContent" sideOffset={5}>
          <DropdownMenu.Item
              className="DropdownMenuItem text-left hover:text-white text-gray-900"
          >
            <i className='bi bi-person-plus pr-1' /> Profile
          </DropdownMenu.Item>
          <DropdownMenu.Separator className="DropdownMenuSeparator" />
          <DropdownMenu.Item 
             className="DropdownMenuItem text-left hover:text-white text-red-500"
             onClick={ () => {
                navigate('/')
                sessionStorage.clear()
                localStorage.clear()
             } }
          >
            <i className='bi bi-box-arrow-in-left pr-1' /> Logout
          </DropdownMenu.Item>
          <DropdownMenu.Separator className="DropdownMenuSeparator hidden" />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
       </DropdownMenu.Root>
        </div>
    </div>
  )
}

export default Navbar