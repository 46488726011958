import React, { useState, useEffect, useRef } from 'react';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import { MdOutlineMoreHoriz } from 'react-icons/md';

const VerticalMenu = ({ children, isBackground, width, icon }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef();

  const handleOutsideClick = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isMenuOpen]);

  
  return (
    <div className='relative' ref={menuRef}>
      {icon === 'horizontal' ? (
        <i  className={`bi bi-three-dots  cursor-pointer ${isBackground ? 'text-blue-500' : ''}`} onClick={() => setIsMenuOpen(!isMenuOpen)} />
      ) : (
        <i className={`bi bi-three-dots-vertical cursor-pointer ${isBackground ? 'text-blue-500' : ''}`} onClick={() => setIsMenuOpen(!isMenuOpen)} />
      )}

      {isMenuOpen && (
        <div
          style={{ right: '0', top: '23px', zIndex: '50' }}
          className={`absolute w-fit mt-1 h-auto ease-in-out transition-all overflow-hidden origin-top-right bg-white rounded-md shadow-lg  ring-1 ring-black ring-opacity-5`}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default VerticalMenu;
