import React from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../../../assets/logo-2.png";
import { isDarkColor } from "../../../../components/utils/themeDetector";

const Sidebar_ = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const org = JSON.parse(sessionStorage.getItem("user"));
  const user = JSON.parse(sessionStorage.getItem("user"));
  const accountType = user?.role?.slug;
  const isClientAdmin = accountType === "client-admin";
  const themes = sessionStorage.getItem("account-customization");

  return (
    <div>
      <section>
        <div
          style={{
            backgroundColor:
              org?.orgAppearance?.themeColour || themes
                ? `${
                    themes
                      ? JSON.parse(themes)?.theme
                      : org?.orgAppearance?.themeColour
                  }`
                : "#0A3059",
          }}
          className={` text-white relative shadow`}
        >
          <Sidebar
            rootStyles={{
              height: "100vh",
              //backgroundColor: "#091F46",
              opacity: 1,
              paddingRight: 10,
              paddingLeft: 10,
            }}
            width={"250px"}
          >
            <Menu
              menuItemStyles={{
                button: ({ level, active, disabled }) => {
                  return {
                    color: active
                      ? "#FF9100 !important"
                      : org?.orgAppearance?.themeColour || themes
                      ? isDarkColor(
                          themes
                            ? JSON.parse(themes)?.theme
                            : org?.orgAppearance?.themeColour
                        )
                        ? "#FFFFFF !important"
                        : "#222 !important"
                      : undefined,
                    backgroundColor: active ? "#FFFFFF" : "transparent",
                    borderRadius: active ? "10px" : 0,
                  };
                },
              }}
            >
              <div className="border-b mb-1 border-gray-600 flex items-center pt-3 pb-2 px-3">
                <img
                  src={
                    themes
                      ? JSON.parse(themes)?.logo
                      : org?.orgAppearance?.desktopLogo
                      ? org?.orgAppearance?.desktopLogo
                      : logo
                  }
                  alt="logo"
                  className="w-[120px] h-[50px] pl-5"
                />
              </div>
              <MenuItem
                active={location.pathname.includes("/admin/dashboard")}
                icon={<span className="bi bi-grid text-2xl"></span>}
                component={<Link to={"/admin/dashboard"} />}
              >
                Dashboard
              </MenuItem>

              {!isClientAdmin ? (
                <MenuItem
                  active={location.pathname.includes("/admin/clients")}
                  icon={<span className="bi bi-people text-2xl"></span>}
                  component={<Link to={"/admin/clients"} />}
                >
                  Client Management
                </MenuItem>
              ) : null}

              {!isClientAdmin ? (
                <SubMenu
                  icon={<span className="bi bi-mortarboard text-2xl"></span>}
                  label="Course Management"
                >
                  <MenuItem
                    active={location.pathname.includes("/admin/course/")}
                    component={<Link to={"/admin/course/"} />}
                  >
                    <i className="bi bi-mortarboard"></i>{" "}
                    <span className="text-xs">Courses</span>
                  </MenuItem>
                  <MenuItem
                    active={location.pathname.includes("/admin/courses/module")}
                    component={<Link to={"/admin/courses/module"} />}
                  >
                    <i className="bi bi-box-seam"></i>{" "}
                    <span className="text-xs">Modules</span>
                  </MenuItem>
                  {/*<MenuItem
                active={location.pathname.includes("/admin/courses/analytics")}
                component={<Link to={"/admin/courses/analytics"} />}
              >
                <span className="text-xs">Analytics</span>
          </MenuItem>*/}
                </SubMenu>
              ) : null}

              {!isClientAdmin ? (
                <SubMenu
                  icon={<span className="bi bi-person-gear text-2xl"></span>}
                  label="User Management"
                >
                  <MenuItem
                    active={location.pathname.includes("/roles")}
                    component={<Link to={"/admin/user-management/roles"} />}
                  >
                    <i className="bi bi-database-lock"></i> Roles & Permissions
                  </MenuItem>
                </SubMenu>
              ) : null}

              {isClientAdmin ? (
                <SubMenu
                  icon={<span className="bi bi-people text-2xl"></span>}
                  label="Cohort Management"
                  className=""
                >
                  <MenuItem
                    active={location.pathname.includes("/admin/cohort/")}
                    component={<Link to={"/admin/cohort/"} />}
                  >
                    <i className="bi bi-person-video2"></i>{" "}
                    <span className="text-xs">Cohort</span>
                  </MenuItem>
                  <MenuItem
                    active={location.pathname.includes("/teams")}
                    component={<Link to={"/admin/learners/teams"} />}
                  >
                    <i className="bi bi-microsoft-teams"></i> Teams
                  </MenuItem>
                  <MenuItem
                    active={location.pathname === "/admin/learners"}
                    component={<Link to={"/admin/learners"} />}
                  >
                    <i className="bi bi-person-workspace"></i> Learners
                  </MenuItem>
                  <MenuItem
                    active={location.pathname.includes("/admin/rewards")}
                    component={<Link to={"/admin/rewards"} />}
                  >
                    <i className="bi bi-trophy"></i> Rewards
                  </MenuItem>
                  <MenuItem
                    active={location.pathname.includes("/action-plan")}
                    component={<Link to={"/admin/learners/action-plan"} />}
                  >
                    <i className="bi bi-app-indicator"></i> Action Plan
                  </MenuItem>
                  <MenuItem
                    active={location.pathname.includes("/quiz-and-sports")}
                    component={<Link to={"/admin/learners/quiz-and-sports"} />}
                  >
                    <i className="bi bi-bar-chart"></i> Quiz & Inter House
                    Sports
                  </MenuItem>
                </SubMenu>
              ) : null}

              {isClientAdmin ? (
                <MenuItem
                  active={location.pathname.includes("/admin/learning-path")}
                  component={<Link to={"/admin/learning-path"} />}
                  className="text-2xl"
                  icon={<i className="bi text-2xl bi-bar-chart-steps"></i>}
                >
                  <span className="">Learning Path</span>
                </MenuItem>
              ) : null}

              {/* {isClientAdmin ? ( */}
              <SubMenu
                icon={<span className="bi bi-brightness-high text-2xl"></span>}
                label="Reports & Analysis"
                className="hidden"
              >
                <MenuItem
                  active={
                    location.pathname === "/admin/report-and-analysis/overview"
                  }
                  component={
                    <Link to={"/admin/report-and-analysis/overview"} />
                  }
                >
                  Overview
                </MenuItem>
                <MenuItem
                  className="hidden"
                  active={location.pathname.includes(
                    "/admin/report-and-analysis/learners"
                  )}
                  component={
                    <Link to={"/admin/report-and-analysis/learners"} />
                  }
                >
                  Learners
                </MenuItem>
                <MenuItem
                  active={location.pathname.includes(
                    "/admin/report-and-analysis/courses"
                  )}
                  component={<Link to={"/admin/report-and-analysis/courses"} />}
                >
                  Courses
                </MenuItem>
              </SubMenu>
              {/* ) : null} */}

              {/* {isClientAdmin && ( */}
              <MenuItem
                active={location.pathname.includes("/learners/analytics")}
                component={<Link to={"/admin/learners/analytics"} />}
                className="text-2xl"
                icon={<i className="bi text-2xl bi-graph-up-arrow"></i>}
              >
                <span className="">Analytics</span>
              </MenuItem>
              {/* )} */}

              {isClientAdmin && (
                <SubMenu
                  icon={<span className="bi bi-megaphone text-2xl"></span>}
                  label="Communication"
                  className=""
                >
                  <MenuItem
                    active={location.pathname.includes("/admin/zoom")}
                    component={<Link to={"/admin/zoom"} />}
                  >
                    <i className="bi bi-camera-reels"></i>{" "}
                    <span className="text-xs">Zoom</span>
                  </MenuItem>
                  <MenuItem
                    active={location.pathname.includes("/admin/email")}
                    component={<Link to={"/admin/email"} />}
                  >
                    <i className="bi bi-envelope-arrow-down"></i>{" "}
                    <span className="text-xs">Email</span>
                  </MenuItem>
                </SubMenu>
              )}

              {isClientAdmin && (
                <MenuItem
                  active={location.pathname.includes("/admin/certificate")}
                  icon={<span className="bi bi-qr-code-scan text-2xl "></span>}
                  component={<Link to={"/admin/certificate"} />}
                >
                  Certificates
                </MenuItem>
              )}

              {isClientAdmin ? (
                <SubMenu
                  icon={<span className="bi bi-credit-card text-2xl"></span>}
                  label="Billing"
                >
                  <MenuItem
                    active={location.pathname === "/admin/billing/summary"}
                    component={<Link to={"/admin/billing/summary"} />}
                  >
                    <i className="bi bi-sliders2-vertical"></i> Summary
                  </MenuItem>
                  <MenuItem
                    active={location.pathname === "/admin/billing/invoice"}
                    component={<Link to={"/admin/billing/invoice"} />}
                  >
                    <i className="bi bi-receipt"></i> Invoice
                  </MenuItem>
                  <MenuItem
                    active={location.pathname.includes(
                      "/admin/billing/pricing"
                    )}
                    component={<Link to={"/admin/billing/pricing"} />}
                    className="hidden"
                  >
                    Pricing & Calculator
                  </MenuItem>
                </SubMenu>
              ) : (
                <SubMenu
                  icon={<span className="bi bi-credit-card text-2xl"></span>}
                  label="Billing"
                >
                  <MenuItem
                    active={location.pathname === "/admin/billing/summary"}
                    component={<Link to={"/admin/billing/summary"} />}
                  >
                    <i className="bi bi-sliders2-vertical"></i> Summary
                  </MenuItem>

                  {/*<MenuItem
              active={location.pathname.includes("/admin/billing/pricing")}
              component={<Link to={"/admin/billing/pricing"} />}
            >
              Pricing & Discount
            </MenuItem>*/}
                  <MenuItem
                    active={location.pathname.includes(
                      "/admin/billing/invoice"
                    )}
                    component={<Link to={"/admin/billing/invoice"} />}
                  >
                    <i className="bi bi-receipt"></i> Invoice
                  </MenuItem>
                  {/*<MenuItem
              active={location.pathname.includes("/admin/billing/transactions")}
              component={<Link to={"/admin/billing/transactions"} />}
              className="hidden"
            >
              Transactions
            </MenuItem>*/}
                  <MenuItem
                    active={location.pathname.includes(
                      "/admin/billing/discount"
                    )}
                    component={<Link to={"/admin/billing/discount"} />}
                  >
                    <i className="bi bi-coin"></i> Discounts
                  </MenuItem>
                  <MenuItem
                    active={location.pathname.includes(
                      "/admin/billing/course-credit-code"
                    )}
                    component={
                      <Link to={"/admin/billing/course-credit-code"} />
                    }
                  >
                    <i className="bi bi-shield-lock"></i> Course Credit Code
                  </MenuItem>
                </SubMenu>
              )}

              {isClientAdmin ? ( 
                <MenuItem
                  active={location.pathname.includes("/admin/feedback")}
                  icon={<span className="bi bi-headset text-2xl "></span>}
                  component={<Link to={"/admin/feedback"} />}
                >
                  Feedback
                </MenuItem>
              ) : null}
              {isClientAdmin ? (
                <MenuItem
                  active={location.pathname.includes("/admin/content-library")}
                  icon={<span className="bi bi-collection text-2xl "></span>}
                  component={<Link to={"/admin/content-library"} />}
                >
                  Content Library 
                </MenuItem>
              ) : null}

              {/* {isClientAdmin ? (
                <SubMenu
                  icon={<span className="bi bi-people text-2xl"></span>}
                  label="Learners"
                >
                  
                </SubMenu>
              ) : null} */}

              <MenuItem
                active={location.pathname.includes("/admin/settings")}
                icon={<span className="bi bi-gear text-2xl "></span>}
                component={<Link to={"/admin/settings/admins"} />}
              >
                Settings
              </MenuItem>
            </Menu>
          </Sidebar>
        </div>
      </section>
    </div>
  );
};

export default Sidebar_;
