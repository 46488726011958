import React, { useState } from "react";
import { useQuery } from "react-query";
import toast from "react-hot-toast";
import { ClipLoader } from "react-spinners";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";

import { api } from "../../../../../api/content";
import useSelectStyles from "../../../../../hooks/useSelectStyles";
import CenteredModal from "../../../../../components/Modal/CenteredModal";
import MultiSelectInput from "../components/MultiSelectInput";
import { createResource } from "../../../../../api/uploadResourse";

const options = [
  { value: "image", label: "Image", extensions: ["jpg", "jpeg", "png", "gif"] },
  { value: "audio", label: "Audio", extensions: ["mp3", "wav", "aac"] },
  { value: "ppt", label: "PPT", extensions: ["ppt", "pptx"] },
  { value: "pdf", label: "PDF", extensions: ["pdf"] },
  { value: "video", label: "Video", extensions: ["mp4", "mov", "avi", "mkv"] },
];

const CreateContent = ({
  openCreateContentModal,
  setOpenCreateContentModal,
  selectedContent,
  setSelectedContent,
  refetch,
}) => {
  const user = JSON.parse(window.sessionStorage.getItem("user"));
  const orgId = user?.org?.id;

  const isEditMode = !!selectedContent;
  const selectStyles = useSelectStyles();
  const [contentType, setContentType] = useState(
    selectedContent?.type
      ? { value: selectedContent.type, label: selectedContent.type }
      : null
  );
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [filePath, setFilePath] = useState("");

  const [selectedTags, setSelectedTags] = useState([]);

 
  const handleFileChange =  async (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;

    setLoading(true); // Start loading
    const toastId = toast.loading("Uploading file...");

    // Find the selected option for content type
    const selectedOption = options.find(
      (option) => option.value === contentType
    );

    // If no content type is selected, show an error and stop the process
    if (!selectedOption) {
      toast.error("Please select a content type before uploading a file.", {
        id: toastId,
      });
      setLoading(false); // Stop loading
      return;
    }

   // Extract the file extension and validate it
  const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
  if (!selectedOption.extensions.includes(fileExtension)) {
    toast.error(
      `Invalid file type. Only ${selectedOption.extensions.join(", ")} files are allowed.`,
      { id: toastId }
    );
    setFile(null);
    setLoading(false); // Stop loading
    return;
  }

  try {
    // Create FormData and append the selected file
    const formData = new FormData();
    formData.append("file", selectedFile);

    // Call the upload API endpoint
    const response = await createResource.uploadResource(formData);
    console.log(response.data, "res data")

    // Set the file path from the response
    setFilePath(response?.data?.data);
    setFile(selectedFile);

    // Show a success message
    toast.success(response?.data?.message || "File uploaded successfully!", {
      id: toastId,
    });
  } catch (error) {
    // Handle error and show error message
    toast.error(
      error.response?.data?.message || "Failed to upload file. Please try again.",
      { id: toastId }
    );
  } finally {
    setLoading(false); // Stop loading
  }
  };

  const initialValues = {
    name: selectedContent?.title || "",
    description: selectedContent?.description || "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name field is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const payload = {
        title: values.name,
        description: values.description,
        type: contentType,
        size: file?.size,
        status: status, // Determined by button click
        tagIds: selectedTags, // Optional
        filePath: filePath,
      };
      console.log(payload, "payload");
      if (isEditMode) {
        const response = await api.updateContent({
          contentId: selectedContent.id,
          orgId: orgId,
          payload,
        });
        toast.success(response?.message);
        setSelectedContent(null);
      } else {
        const response = await api.createContent({
          orgId: orgId,
          payload,
        });
        toast.success(response?.message);
      }

      refetch();
      setOpenCreateContentModal(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <CenteredModal
      title={isEditMode ? "Edit Content" : "Create Content"}
      hasHeader={true}
      open={openCreateContentModal}
      setOpen={setOpenCreateContentModal}
      width={"w-[600px]"}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="w-full p-6 bg-white text-left">
              <div className="mb-4">
                <div className="flex flex-col mb-4 text-left">
                  <label htmlFor="name" className="mb-1 text-base font-medium">
                    Name
                  </label>
                  <Field
                    type="text"
                    id="name"
                    name="name"
                    className="input-style bg-primaryGray"
                    placeholder="Enter Name"
                  />
                  <ErrorMessage
                    name="name"
                    component="p"
                    className="error-style"
                  />
                </div>

                <div className="flex flex-col mb-4 text-left">
                  <label
                    htmlFor="description"
                    className="mb-1 text-base font-medium"
                  >
                    Description
                  </label>
                  <Field
                    type="text"
                    id="description"
                    name="description"
                    className="input-style bg-primaryGray"
                    placeholder="Enter Description"
                  />
                  <ErrorMessage
                    name="description"
                    component="p"
                    className="error-style"
                  />
                </div>

                <div className="mb-6">
                  <label className="block text-sm font-medium mb-2">
                    Select Content Type
                  </label>
                  <Select
                    options={options}
                    onChange={(selectedOption) =>
                      setContentType(selectedOption?.value || null)
                    }
                    placeholder="Choose content type..."
                    // styles={selectStyles}
                    className="text-base z-10"
                  />
                </div>

                <div className="">
                  <label className="block text-sm font-medium mb-2">Tags</label>
                  <MultiSelectInput
                    selectedValues={selectedTags}
                    onValuesChange={setSelectedTags}
                    orgId={orgId}
                    styles={selectStyles}
                    className="text-base"
                  />
                </div>
              </div>

              {/* Upload Field */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Upload
                </label>
                <div className="flex items-center border border-gray-300 bg-gray-100 rounded-lg overflow-hidden">
                  <input
                    type="file"
                    onChange={handleFileChange}
                    className="flex-1 bg-transparent p-3 text-sm text-gray-500 placeholder-gray-400 focus:outline-none"
                    disabled={loading} // Disable input during loading
                  />
                  <button
                    className={`bg-primaryBlue text-white p-3 flex items-center justify-center ${
                      loading ? "opacity-50" : ""
                    }`}
                    type="button"
                    disabled={loading}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4 16v4a1 1 0 001 1h14a1 1 0 001-1v-4M16 8l-4-4m0 0l-4 4m4-4v12"
                      />
                    </svg>
                  </button>
                </div>
                {file && (
                  <p className="text-green-500 text-sm mt-2">
                    File uploaded: {file.name}
                  </p>
                )}
              </div>

              <div className="flex justify-between mt-6">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="bg-primaryBlue text-white px-4 py-2 rounded-md flex items-center"
                  onClick={() => setStatus("draft")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 mr-2"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 16v4a1 1 0 001 1h14a1 1 0 001-1v-4M16 8l-4-4m0 0l-4 4m4-4v12"
                    />
                  </svg>
                  Save as Draft
                </button>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="bg-primaryBlue text-white px-4 py-2 rounded-md flex items-center"
                  onClick={() => setStatus("published")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 mr-2"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 16v4a1 1 0 001 1h14a1 1 0 001-1v-4M16 8l-4-4m0 0l-4 4m4-4v12"
                    />
                  </svg>
                  Publish
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </CenteredModal>
  );
};

export default CreateContent;
