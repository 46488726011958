import React from "react";
import { Routes, Route } from "react-router";
import SettingsLayout from "./SettingsLayout";
import ZoomApi from "../zoom/pages/ZoomApi";
import MultiStepForm from "../email/components/MultiStepForm";
import EmailSettingStep3 from "../email/pages/EmailSettingStep3";
import RolesAndPrivileges from "../roles-and-priviledges/RolesAndPrivileges";
import AdminManagement from "../admin-management/AdminManagement";
import ProfileAndAccountSetup from "./pages/profile-and-account-setup/ProfileAndAccountSetup";

const SettingsRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<SettingsLayout />}>
        <Route path="admins" element={<AdminManagement />} />
        <Route path="role-and-privileges" element={<RolesAndPrivileges />} />
        <Route path="zoom" element={<ZoomApi />} />
        <Route path="email" element={<MultiStepForm />} />
        <Route path="email/test-email" element={<EmailSettingStep3  />} />
        <Route path="profile" element={<ProfileAndAccountSetup />} />
      </Route>
    </Routes>
  );
};

export default SettingsRoutes;
