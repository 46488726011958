import React from "react";
import Sidebar from "./layout/sidebar/Sidebar";
import Header from "./layout/header/Header";
import { Routes, Route } from "react-router";
import Dashboard from "./pages/dashboard/Dashboard";
import Client from "./pages/client-beta/ClientRoutes";
import Learners from "./pages/learners/Learners";
//import Courses from "./pages/courses-beta/Courses";
import Courses from "./pages/courses/Courses";
import RewardRoutes from "./pages/rewards/RewardRoutes";
//import LearningPath from "./pages/learning-path-beta/PathRoutes";
//import Module from "./pages/module-beta/Module";
import Analytics from "./pages/analytics/Analytics";
import UserManagement from "./pages/user-management/UserManagement";
import ReportAndAnalysisRoutes from "./pages/report-and-analysis/ReportAndAnalysisRoutes";
import BillingRoutes from "./pages/billing/site-admin/BillingRoutes";
import ClientBillingRoutes from "./pages/billing/client-admin/BillingRoutes";

import ActionPlanRoutes from "./pages/action-plan/ActionPlanRoute";


import CertificateRoutes from "./pages/certificate-editor/CertificateRoutes";
import Navbar from "./layout/header/Navbar";
import Clients from "./pages/clients/Client";
import Modules from "./pages/modules/Modules";
import Cohorts from "./pages/cohorts/Cohorts";
import LearningPath from "./pages/learningPath/LearningPath";
import RolesPrivilegesRoutes from "../../app/admin/pages/roles-and-priviledges/RolesPrivilegesRoutes";
import AdminMangementRoute from "../../app/admin/pages/admin-management/AdminMangementRoute";
import EmailEditorRoutes from "./pages/email/EmailEditorRoutes";
import ZoomRoutes from "./pages/zoom/ZoomRoutes";
import SettingsRoutes from "./pages/settings/SettingsRoutes";
import Feedback from "./pages/feedback/Feedback";
import ContentRoutes from "./pages/content-libary/ContentRoutes";

const AdminRoutes = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const accountType = user?.role?.slug;
  const isClientAdmin = accountType === "client-admin";
  const org = JSON.parse(sessionStorage.getItem('user'))
  const themes = sessionStorage.getItem('account-customization')

  return (
    <div className="flex justify-between overflow-y-hidden">
      <div className="w-[250px]">
        <Sidebar />
      </div>
      <div className="text-gray-900 w-[calc(100%-250px)]">
        <section 
          className="h-[calc(100vh)] px-5 pt-2 admin-container-panel overflow-y-auto"
          style={{
            backgroundColor : themes ? JSON.parse(themes)?.bodyColor :
            org?.orgAppearance?.bodyBackgroundColour || "#F2F4F7"
          }}
        >
          <Navbar />
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/clients/*" element={<Clients />} />
            <Route path="/learners/*" element={<Learners />} />
            <Route path="/course/*" element={<Courses />} />
            <Route path="/courses/module/*" element={<Modules />} />
            <Route path="/courses/analytics" element={<Analytics />} />
            <Route path="/cohort/*" element={<Cohorts />} />
            <Route path="/learning-path/*" element={<LearningPath />} />
            <Route path="/user-management/*" element={<UserManagement />} />
            <Route
              path="/billing/*"
              element={
                isClientAdmin ? <ClientBillingRoutes /> : <BillingRoutes />
              }
            />
            <Route path="/rewards/*" element={<RewardRoutes />} />
            <Route
              path="/report-and-analysis/*"
              element={<ReportAndAnalysisRoutes />}
            />
            <Route path="/zoom/*" element={<ZoomRoutes />} />
            {/* <Route path="/settings/*" element={<SettingRoutes />} /> */}
            <Route path="/action-plan/*" element={<ActionPlanRoutes />} />
            <Route path="/settings/*" element={<SettingsRoutes />} />
            <Route path="/email/*" element={<EmailEditorRoutes />} />
            <Route path="/certificate/*" element={<CertificateRoutes />} />
            <Route path="/roles-and-privileges/*" element={<RolesPrivilegesRoutes />} />
            <Route path="/admins/*" element={<AdminMangementRoute />} />
            <Route path='/feedback/*' element={<Feedback />} />
            <Route path='/content-library/*' element={<ContentRoutes />} />
          </Routes>
        </section>
      </div>
    </div>
  );
};

export default AdminRoutes;
