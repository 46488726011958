import React from 'react'
import LearnerProfileSection from './components/LearnerProfileSection'
import HorizontalTab from '../../../../../components/Tab/HorizontalTab'
import CurrentCohort from './components/CurrentCohort';
import OverallHistory from './components/OverallHistory';

const LearnerProfile = () => {
    const tabsData = [
        {
          label: `Current Cohort`,
          content: <CurrentCohort />,
        },
        {
          label: `OverAll History`,
          content: <OverallHistory  />,
        } 
      ];
  return (
    <div className='p-5 md:flex gap-6'>
        
        <div className="w-1/4">
        <LearnerProfileSection />
        </div>
        <div className="w-3/4">
        <HorizontalTab tabsData={tabsData} />
        </div>
    </div>
  )
}

export default LearnerProfile