import React, { useMemo } from "react";
import Table from "../../../../../components/table/Table";
import Pagination from "../../../../../components/pagination/Pagination";
import { ScaleLoader } from "react-spinners";

const ActiveLearners = ({
  data,
  isLoading,
  perPage,
  setPerPage,
  currentPage,
  setCurrentPage,
}) => {

  const columns = useMemo(
    () => [
      {
        title: "ID",
        selector: null,
        key: "id",
        render: ({ index }) =>
          index +
          1 
          
      },
      {
        title: "Name",
        selector: "name",
        key: "name",

        render: ({ row }) => (
          <span> {row?.user?.name}</span>
        
        ),
      },
      {
        title: "Email",
        selector: "email",
        key: "email",
        render: ({ row }) => (
          <span className="">
            {row?.user?.email}
          </span>
        ),
      },
      {
        title: "Completed Course",
        selector: "completedCoursesCount",
        key: "completedCoursesCount",
        render: ({ row }) => (
          <span className="">
            {row?.completedCoursesCount}
          </span>
        ),
      },
    ],
    []
  );

  const tableProps = {
    isLoading: isLoading,
    columns,
    data: data?.data || [],
  };
  return (
    <div>
      {isLoading ? (
        <div className="mt-10 flex justify-center items-center">
          <ScaleLoader color="#FF9100" />
        </div>
      ) : (
        <div className="bg-white overflow-x-hidden w-full p-5">
          <section className="overflow-x-auto">
          <Table
            {...tableProps}
            style={{
              overflowX: "auto",
            }}
            headerClassName={"border-t bg-gray-100"}
          />
          </section>
          <Pagination
            data={data?.meta}
            perPage={perPage}
            pageNo={currentPage}
            setPageNo={setCurrentPage}
            setPerPage={setPerPage}
          />
        </div>
      )}
    </div>
  );
};

export default ActiveLearners;
