import React, { useState } from 'react';

const HorizontalTab = ({ tabsData, withPadding=true }) => {

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  if(tabsData?.length === 1) return tabsData[0]?.content

  return (
    <div className={`rounded-sm shadow-xxl bg-white ${withPadding ? 'p-4' : ''} tracking-normal `}>
      <div className='flex justify-between  border-b border-[#EAEAEA] px-2 rounded-lg'>
        {tabsData?.map((tab, idx) => (
          <button
          type='button'
            key={idx}
            className={` w-full py-2  transition-colors duration-300 text-xs ${
              idx === activeTabIndex
                ? 'text-[#ED6F1E] font-semibold   border-b-2 border-[#FF9100]'
                : 'border-transparent hover:border-gray-200'
            }`}
            onClick={() => setActiveTabIndex(idx)}
          >
            {tab?.label}
          </button>
        ))}
      </div>
      <div className={`${withPadding ? 'py-4' : ''}`}>
        <span>{tabsData[activeTabIndex]?.content}</span>
      </div>
    </div>
  );
};

export default HorizontalTab;
