import { ErrorMessage, Field, Form, Formik, FormikErrors } from 'formik'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import profileIcon from '../../../../../../../assets/profile-add.png'
import toast from 'react-hot-toast'
import { api as auth } from '../../../../../../../api'
import { errorResponse } from '../../../../../../../components/utils/errorResponse'
import { createResource } from '../../../../../../../api/uploadResourse'
import ResourceLoader from '../../../../../../../components/loader/ResourceLoader'
import ActionModal from '../../../../../../../components/popUp/ActionModal'
import { useNavigate } from 'react-router-dom'
import { refetchType } from '../ProfileAndAccountSetup'

type initialValuesType = {
   name: string,
   email: string,
   number: string,
   role: string,
   photo: string | null
}

type profileInformationDataType = {
 name: string,
 photo: string | null
 phoneNumber: string 
 roleTitle: string 
 email: string
}

type ProfileInformationType = {
  setCurrentStep?: React.Dispatch<React.SetStateAction<number>>
  isClient: boolean,
  profileInformationData: profileInformationDataType
  refetch: refetchType
}

export type setFieldValueType = (
            field: string, value: any, shouldValidate?: boolean
         ) => Promise<void | FormikErrors<initialValuesType>>

const ProfileInformation: React.FC<ProfileInformationType> = ({ 
     profileInformationData, isClient=true, refetch
   }) => {

  const [ logoPreview, setLogoPreview ] = useState<null|string>(null)
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [showSuccessPopUp, setShowSuccessPopUp] = useState<boolean>(false)
  const org: any = JSON.parse(sessionStorage.getItem('user')!)

  const initialValues: initialValuesType = {
    name: profileInformationData?.name, 
    email: profileInformationData?.email, 
    number: profileInformationData?.phoneNumber,
    role: profileInformationData?.roleTitle,
    photo: profileInformationData?.photo
  }

  const validationSchema = Yup.object<Yup.AnyObject, {}>().shape({    
    name: Yup.string().required('Error! name field is required.'),
    email: Yup.string().email().required('Error! email address is required.'),
    number: Yup.string().required('Error! phone number is required')
            .max(20,'Error! phone number is too long maximum is 20 digits'),
    role: Yup.string().required('Error! role field is required.')
  });


 const uploadClientProfile = async ( 
    event: React.ChangeEvent<HTMLInputElement>, setFieldValue: setFieldValueType
   ) : Promise<void> => {
         setIsUploading(true)
         try{
            const file: File =  event?.target?.files![0]; 
         if (file) {
            const formData: FormData = new FormData();
            formData.append("file", file);
            const response = await createResource.uploadResource(formData)
            toast.success(response?.data?.message)
            setFieldValue('photo',response?.data?.data)
            setIsUploading(false)
         } else setLogoPreview(null);
         } catch(error){
            setIsUploading(false)
            errorResponse(error)
         }
 }

  const createProfile = async (values: initialValuesType, actions:any) : Promise<void>=> {
   actions.setSubmitting(false);
    try{
      sessionStorage.setItem('profile-information', JSON.stringify(values))
      toast.loading('Loading...')
      let payload = {
         email: values.email,
         name: values.name,
         phoneNumber: values.number,
         photo: values.photo,
         roleTitle: values.role
       }
       let response: any;
        response = await auth.updateRootAccount(payload, org?.userId)
      toast.remove()
      toast.success(response?.message)
      refetch()
      window.location.reload()
    }
    catch(error: any){
       toast.remove()
       toast.error(error?.response?.data?.message)
    }
  };

  return (
    <div> 
        { isUploading && <ResourceLoader /> }
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={createProfile}
        >
          {({ values, setFieldError, setFieldValue, setFieldTouched }) => (
            <Form> 
                <header>
                  <h5 className='text-lg font-semibold flex items-center justify-between'>
                     Profile Information  
                  </h5>
                </header>
                 <section className='grid grid-cols-2 gap-5 mt-4'>
                       <div>
                        <label htmlFor="name" className='text-xs'>
                           Full Name <span className='text-lg text-red-500'>*</span>
                        </label>
                         <Field 
                             id='name' className="input-style bg-primaryGray" 
                             name='name' 
                          />
                          <p className='mt-2 text-red-500 text-sm'>
                            <ErrorMessage name='name' />
                          </p>
                       </div>
                       <div>
                        <label htmlFor="email" className='text-xs'>
                           Email Address <span className='text-lg text-red-500'>*</span>
                        </label>
                         <Field 
                             id='email' type='email' className="input-style bg-primaryGray" 
                             name='email' readOnly={isClient}
                          />
                          <p className='mt-2 text-red-500 text-sm'>
                            <ErrorMessage name='email' />
                          </p>
                       </div>
                 </section>
                 <section className='grid grid-cols-2 gap-5 mt-4'>
                       <div>
                        <label htmlFor="number" className='text-xs'>
                           Phone Number <span className='text-lg text-red-500'>*</span>
                        </label>
                         <Field 
                             id='number' className="input-style bg-primaryGray" 
                             name='number'
                          />
                          <p className='mt-2 text-red-500 text-sm'>
                            <ErrorMessage name='number' />
                          </p>
                       </div>
                       <div>
                        <label htmlFor="role" className='text-xs'>
                           Role <span className='text-lg text-red-500'>*</span>
                        </label>
                         <Field 
                             id='role' className="input-style bg-primaryGray" 
                             name='role' placeholder='e.g manager...'
                          />
                          <p className='mt-2 text-red-500 text-sm'>
                            <ErrorMessage name='role' />
                          </p>
                       </div>
                 </section>
                     <section className='py-3 px-3 border-2 flex justify-between border-dotted border-slate-300 mt-5 rounded-lg'>
                         <div className='flex'>
                         <div className={`w-[80px] h-[80px] ${
                           !values.photo ? 'bg-slate-200' : ''
                         } rounded-full flex justify-center items-center`}>
                            <img src={ values.photo ? values.photo : profileIcon} 
                              className='w-[50px] rounded-full h-[50px]' alt="profile icon"
                            />
                        </div>
                        <div className='pt-2 pl-2'>
                             <h4 className='text-md font-light'>
                                Upload a picture of {isClient ? 'yourself' : 'client'}
                             </h4>
                             <p className='text-xs font-light'>
                                Upload a clear picture of {isClient ? 'yourself' : 'client'}. Image should be at least 80x80 px
                             </p>
                        </div>
                         </div>
                        <div className='flex items-center'>
                           <input 
                              type="file" className='hidden' id='profile'
                              onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
                                uploadClientProfile(event, setFieldValue)
                              }}
                           />
                           <label htmlFor="profile" className='bg-secondary text-white text-xs py-2 px-8 rounded'>
                              Upload
                           </label>
                        </div>
                     </section>
                     <section className='mt-8 flex justify-between'>
                        <button type='submit' className='bg-secondary text-white text-xs py-3 px-8 rounded'>
                           Update Profile 
                        </button>
                     </section>
            </Form>
          )}
    </Formik>
    </div>
  )
}

export default ProfileInformation