import React, { useState, useEffect } from 'react'
import Button from '../../../../../../../../components/Buttons/Button'
import OverviewCard from '../../../../../report-and-analysis/components/OverviewCard'
import BadgeIcon from '../../../../../../../../assets/Badge.png'
import BadgeIconGreen from '../../../../../../../../assets/Badge (1).png'
import PaymentHistory from '../components/InvoiceTable'
import Search from '../../../../../../../../components/Inputs/Search'
import { api } from '../../../../../../../../api/billing'
import { useQuery } from 'react-query'
import { formatCurrency } from '../../summary/Summary'

const Invoice = () => {
    
const [search, setSearch] = useState("");

const { data: invoices, isLoading, refetch } = useQuery(
    ["site admin invoices", {search} ],
    ({ queryKey }) => {
        return api.getInvoices(queryKey[1]);
    },
    {
        cacheTime: 100,
        staleTime: 100,
        refetchOnWindowFocus: true,
        select: (data) => data?.data,
    });

    useEffect( () => {
      refetch()
    }, [search] )

  return (
    <div className=' p-4'>  
        <header className='text-lg font-semibold flex justify-between items-center'>
            Invoices
            <div className='flex items-center hidden'>
                <Button text={'Generate Report'} width={'w-[160px]'} bgColor={'bg-darkBlue'} />
            </div>
        </header>
        <header className='grid grid-cols-2 center mt-3 gap-5'>
             <OverviewCard 
                title={'Available Invoices'}
                total={ isLoading ? '---' : invoices?.numberOfInvoices }
                iconPath={BadgeIconGreen}
                py={'py-8'}
                headerTextSize='text-2xl'
            />
            <OverviewCard 
                title={'Total Amount'}
                total={ isLoading ? '---' : formatCurrency(invoices?.sumTotalAmount) }
                iconPath={BadgeIcon}
                py={'py-8'}
                headerTextSize='text-2xl'
            />
        </header>
        <div className='p-3 mt-5 bg-white rounded-lg'>
                <h5 className='pb-3 flex justify-between items-center border-b font-medium border-gray-200 text-md'>
                  Clients Invoices
                  <Search 
                     className={'w-[350px]'} placeholder={'e.g Access Bank'}
                     onChange={setSearch}
                   />
                </h5>
                <div className='mt-3'>
                   <PaymentHistory 
                     isLoading={isLoading}
                     paymentHistory={invoices?.invoices}
                   />
                </div>
            </div>
    </div>
  )
}

export default Invoice