import React from 'react';
import toast from 'react-hot-toast';
import { Navigate } from 'react-router-dom';


const PrivateRoute = ({ children }) => {
  const token = JSON.parse(sessionStorage.getItem("user"))?.token;
  const isAuthenticated = Boolean(token);

  if (!isAuthenticated) {
    toast.error("You must be logged in to access this page.");
  }

  return isAuthenticated ? children : <Navigate to="/" />;
};

export default PrivateRoute;
