import React, { useState, useMemo } from 'react';
import { useQuery } from 'react-query';
import { api as learningPath } from '../../../../../api/learningPath';  
import EmptyPlaceHolder from '../../../../../components/EmptyPlaceHolder';
import Pagination from '../../../../../components/pagination/Pagination';
import Table from '../../../../../components/table/Table';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

interface ViewUsersProps {
  activeCohortId: string | null;
}

interface User {
  participant: {
    user: {
      name: string;
      email: string;
      id: string;
    };
  };
  cohort: {
    status: string;
    enrolledAt: string;
    createdAt: string;
    updatedAt: string;
  };
}

interface Meta {
  page: number;
  perPage: number;
  total: number;
}

interface UsersResponse {
  data: User[];
  meta: Meta;
}

const ViewLearners: React.FC = () => {
  const { id } = useParams()
  const activeCohortId = id
  const [perPage, setPerPage] = useState<number>(5);
  const [search, setSearch] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);

  const orgId = JSON.parse(sessionStorage.getItem('user') || '{}')?.org?.id;
  
  const { data: users, isLoading, refetch } = useQuery(
    ["cohort users", orgId, activeCohortId, activeCohortId, search, perPage, currentPage ],
    () => {
      return activeCohortId ? learningPath.getCohortUsers(
        { id: orgId, cohortId: activeCohortId, search, perPage, currentPage }
      ) : null;
    },
    {
      staleTime: 5 * 60 * 1000, // data is considered fresh for 5 minutes
      cacheTime: 30 * 60 * 1000,
      select: (data) => data,
    }
  );

  const columns = useMemo(() => [
    {
      title: "ID",
      selector: null,
      key: "id",
      render: ({ index }: { index: number }) =>
        index + 1 + ((users?.meta?.page || 1) - 1) * (users?.meta?.perPage || perPage),
    },
    {
      title: "Learner Name",
      selector: "name",
      key: "name",
      
      render: ({ row }: { row: User }) => (
        <Link
        to={`/admin/cohort/learner/${row.participant.user.id}`} // Adjust the path as needed
        className="text-blue-600 hover:underline"
      >
        {row?.participant?.user?.name}
      </Link>
      ),
    },
    {
      title: "Email Address",
      selector: "email",
      key: "email",
      render: ({ row }: { row: User }) => (
        <span className="">{row?.participant?.user?.email}</span>
      ),
    },
    {
      title: "Status",
      selector: null,
      key: "status",
      render: ({ row }: { row: User }) => (
        <span className="">{row?.cohort?.status || <EmptyPlaceHolder count={5} />}</span>
      ),
    },
    {
      title: "Enrolled At",
      selector: null,
      key: "enrolledAt",
      render: ({ row }: { row: User }) => (
        <span className="">
          {!row?.cohort?.enrolledAt
            ? <EmptyPlaceHolder count={5} />
            : new Date(row?.cohort?.enrolledAt).toLocaleDateString('en-ng')}
        </span>
      ),
    },
    {
      title: "Date Added",
      selector: null,
      key: "createdAt",
      render: ({ row }: { row: User }) => (
        <span className="">
          {new Date(row?.cohort?.createdAt).toLocaleDateString('en-ng')}
        </span>
      ),
    },
    {
      title: "Last Updated",
      selector: null,
      key: "updatedAt",
      render: ({ row }: { row: User }) => (
        <span className="">
          {new Date(row?.cohort?.updatedAt).toLocaleDateString('en-ng')}
        </span>
      ),
    },
  ], [users?.meta?.page, users?.meta?.perPage, perPage]);

  const tableProps = { isLoading: isLoading, columns, data: users?.data || [] };

  return (
    <>

        <section className='text-left pb-3 px-5'>
          <div className='flex items-center justify-between mt-2 mb-4'>
            <h4 className='text-sm mt-3 font-semibold'>Total Learners ({users?.meta?.total})</h4>
            <div className='w-[300px]'>
            <input 
                type="search" 
                className='input-style bg-primaryGray w-[300px] mt-4'
                placeholder='&#128269; Search learners....'
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <section>
            <Table
              {...tableProps}
              style={{
                overflowX: 'auto',
              }}
              headerClassName={'border-t bg-gray-100'}
            />
          </section>
          <section className='mt-1 px-3 rounded-lg bg-white'>
            <Pagination
              data={users?.meta}
              perPage={perPage}
              pageNo={currentPage}
              setPageNo={setCurrentPage}
              setPerPage={setPerPage}
            />
          </section>
        </section>
   
    </>
  );
}

export default ViewLearners;
