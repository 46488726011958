import React, { useState, useMemo, useCallback } from "react";
import debounce from "lodash.debounce";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import { ScaleLoader } from "react-spinners";
import moment from "moment";
import { api } from "../../../../../api/content";
import Search from "../../../../../components/Inputs/Search";
import Pagination from "../../../../../components/pagination/Pagination";
import ConfirmModal from "../../../../../components/Modal/ConfirmModal";
import CreateContent from "./CreateContent";
import VerticalMenu from "../../../../../components/dropdown/VerticalMenu";
import MenuItem from "../../../../../components/dropdown/MenuItem";
import ViewContentModal from "../components/ViewContentModal";

const ContentLibrary = () => {
  const [openCreateContentModal, setOpenCreateContentModal] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [openViewContentModal, setOpenViewContentModal] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedContent, setSelectedContent] = useState(null);

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [currentDropdown, setCurrentDropdown] = useState(null);

  const [sortBy, setSortBy] = useState("createdAt"); // default sort
  const [filterBy, setFilterBy] = useState(null);

  const user = JSON.parse(window.sessionStorage.getItem("user"));
  const orgId = user?.org?.id;

  const handleViewContent = (content) => {
    setSelectedContent(content);
    setOpenViewContentModal(true);
  };

  // Handle individual selection
  const handleSelect = (id) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((item) => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  // Handle select all
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      setSelectedItems(contents.map((content) => content.id));
    }
    setSelectAll(!selectAll);
  };

  const toggleDropdown = (dropdown) => {
    setCurrentDropdown(currentDropdown === dropdown ? null : dropdown);
  };

  const handleSort = (type) => {
    setSortBy(type);
    setCurrentDropdown(null);
    toast.success(`Sorted by ${type}`);
  };

  const handleFilter = (type) => {
    setFilterBy(type);
    setCurrentDropdown(null);
    toast.success(`Filtered by ${type}`);
  };

  const handleChangeStatus = async (status) => {
    console.log(status, selectedItems);
    const payload = {
      contentIds: selectedItems,
      status: "draft", //published // required if action is update
      action: "update", // delete,
    };
    try {
      const response = await api.applyMultipleAction({ orgId, payload });
      console.log(response, "response");
      toast.success(response.message);
      setCurrentDropdown(null);
      refetch();
    } catch (error) {
      toast.error(error.response.data.message || "Failed to change status");
    }
  };

  const handleDeleteMultiple = async () => {
    const payload = {
      contentIds: selectedItems,
      action: "delete", // delete,
    };
    try {
      const response = await api.applyMultipleAction({ orgId, payload });
      console.log(response, "response");
      toast.success(response.message);
      setCurrentDropdown(null);
      refetch();
    } catch (error) {
      toast.error(error.response.data.message || "Failed to change status");
    }
  };

  const {
    data: contents,
    isLoading,
    refetch,
  } = useQuery(
    ["all-contents", search, perPage, currentPage, sortBy, filterBy],
    async () => {
      const response = await api.getAllContents({
        orgId,
        search,
        perPage,
        currentPage,
        sortBy,
        filterBy,
      });
      return response.data;
    },
    {
      staleTime: 5 * 60 * 1000, // data is considered fresh for 5 minutes
      cacheTime: 30 * 60 * 1000, // data is cached for 30 minutes after becoming stale
    },
    {
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );

  const deleteContent = async () => {
    if (!selectedContent) return;
    try {
      const response = await api.deleteContent({
        orgId,
        contentId: selectedContent.id,
      });
      setDeleteModal(false);
      toast.success(response.message);
      refetch(); // Refresh the data after deletion
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleDownload = (filePath) => {
    if (!filePath) {
      toast.error("No file available to download.");
      return;
    }
    const link = document.createElement("a");
    link.href = filePath; // The filePath should be a valid URL or path to the file
    link.download = filePath.split("/").pop(); // Extract filename from the filePath
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    toast.success("Download initiated!");
  };

  const debouncedSearch = useMemo(() => debounce(setSearch, 300), []);

  return (
    <div className="p-10">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-semibold text-[#091F46] mb-3">
          Content Library
        </h3>
        <div className="flex gap-2 justify-end">
          <button
            type="button"
            onClick={() => {
              setOpenCreateContentModal(true);
              setSelectedContent(null);
            }}
            className="p-3 bg-customBlue text-white text-xs rounded-md"
          >
            <i class="bi bi-plus-lg"></i> Create new content
          </button>
        </div>
      </div>

      <div className="p-4 md:p-6 overflow-auto ">
        <div className="flex gap-4 justify-between items-center mb-3">
          <div className="w-1/2">
            <Search
              onChange={setSearch}
              placeholder="Search Content"
              className={"w-full"}
              // debounceDelay={500} // Optional, defaults to 300ms
            />
          </div>
          <div className="flex gap-2 text-[#667085] text-sm font-medium">
            {/* Sort by Button */}
            <div className="relative">
              <button
                className="bg-white rounded-md shadow-lg border px-4 py-3"
                onClick={() => toggleDropdown("sort")}
              >
                Sort by <i className="ml-1 bi bi-sliders"></i>
              </button>
              {currentDropdown === "sort" && (
                <div className="absolute mt-2 bg-white border rounded-md shadow-lg">
                  <ul>
                    <li
                      onClick={() => handleSort("alphabet")}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                    >
                      Alphabet
                    </li>
                    <li
                      onClick={() => handleSort("createdAt")}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                    >
                      Date
                    </li>
                  </ul>
                </div>
              )}
            </div>

            {/* Filter by Button */}
            <div className="relative">
              <button
                className="bg-white rounded-md shadow-lg border px-4 py-3"
                onClick={() => toggleDropdown("filter")}
              >
                Filter by <i className="ml-1 bi bi-sliders"></i>
              </button>
              {currentDropdown === "filter" && (
                <div className="absolute mt-2 bg-white border rounded-md shadow-lg">
                  <ul>
                    <li
                      onClick={() => handleFilter("draft")}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                    >
                      Draft
                    </li>
                    <li
                      onClick={() => handleFilter("published")}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                    >
                      Published
                    </li>
                  </ul>
                </div>
              )}
            </div>

            <div>
              {selectedItems.length > 0 && (
                <button
                  onClick={() => handleDeleteMultiple()}
                  className="bg-white rounded-md shadow-lg border px-4 py-3"
                >
                  Delete
                  <i className="bi bi-trash3-fill"></i>
                </button>
              )}
            </div>

            {/* Change Status Button */}
            <div className="relative">
              <button
                className="bg-white rounded-md shadow-lg border px-4 py-3"
                onClick={() => toggleDropdown("status")}
              >
                Change Status <i className="ml-1 bi bi-repeat"></i>
              </button>
              {currentDropdown === "status" && (
                <div className="absolute mt-2 bg-white border rounded-md shadow-lg">
                  <ul>
                    <li
                      onClick={() => handleChangeStatus("draft")}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                    >
                      Draft
                    </li>
                    <li
                      onClick={() => handleChangeStatus("published")}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                    >
                      Published
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="bg-white p-3 rounded-xl border shadow-sm">
          {isLoading ? (
            <div className="mt-10 flex justify-center items-center">
              <ScaleLoader color="#FF9100" />
            </div>
          ) : (
            <div className="mt-4">
              <div className="">
                <table className="table-container ">
                  <thead className="table-header">
                    <tr>
                      <th className="table-head text-left">
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />
                      </th>
                      <th className="table-head text-left">Title</th>
                      <th className="table-head text-left">Description</th>
                      <th className="table-head text-left">Type</th>
                      <th className="table-head text-left">Size</th>
                      <th className="table-head text-left">Status</th>
                      <th className="table-head text-left">Create at</th>
                      <th className="table-head text-left">Last Updated</th>
                    </tr>
                  </thead>

                  <tbody className="table-body text-xs">
                    {contents?.length > 0 ? (
                      contents.map((content, index) => (
                        <tr key={index}>
                          <td className="table-data">
                            <input
                              type="checkbox"
                              checked={selectedItems.includes(content.id)}
                              onChange={() => handleSelect(content.id)}
                            />
                          </td>
                          <td className="table-data text-left">
                            {content.title}
                          </td>
                          <td className="table-data text-left">
                            {content.description}
                          </td>
                          <td className="table-data text-left">
                            {content.type}
                          </td>
                          <td className="table-data text-left">
                            {content.size}
                          </td>
                          <td className="table-data text-left">
                            <span
                              className={`px-2 py-1 rounded-md ${
                                content.status === "published"
                                  ? "bg-[#00984C24] text-[#00984C]"
                                  : "bg-[#FF910024] text-[#FF9100]"
                              }`}
                            >
                              {content.status}
                            </span>
                          </td>

                          <td className="table-data ">
                            {moment(content.createdAt).format(
                              "YYYY/MM/DD, h:mm a"
                            )}
                          </td>
                          <td className="table-data ">
                            {moment(content.updatedAt).format(
                              "YYYY/MM/DD, h:mm a"
                            )}
                          </td>

                          <td className="table-data flex justify-center px-2">
                            {/* <span className="flex justify-center"> */}
                            <VerticalMenu>
                              <div className="w-fit flex flex-col gap-2">
                                <MenuItem
                                  icon={
                                    <i className="bi bi-pencil-fill cursor-pointer"></i>
                                  }
                                  label="Edit"
                                  onClick={() => {
                                    setSelectedContent(content);
                                    setOpenCreateContentModal(true);
                                  }}
                                />

                                <MenuItem
                                  icon={
                                    <i className="bi bi-eye cursor-pointer"></i>
                                  }
                                  label="View content"
                                  onClick={() => {
                                    handleViewContent(content);
                                    // setOpenViewContentModal(true);
                                  }}
                                />
                                <MenuItem
                                  icon={
                                    <i className="bi bi-download cursor-pointer"></i>
                                  }
                                  label="Download"
                                  onClick={() =>
                                    handleDownload(content.filePath)
                                  }
                                />
                                <MenuItem
                                  icon={
                                    <i className="bi bi-eye cursor-pointer"></i>
                                  }
                                  label="View meta"
                                  onClick={() => {
                                    // setCertificateId(certificate.template.id)
                                    // setOpenViewCertificateModal(
                                    //   true
                                    // );
                                  }}
                                />

                                <MenuItem
                                  icon={
                                    <i className="bi bi-trash3-fill  text-red-500 cursor-pointer"></i>
                                  }
                                  label="Delete"
                                  onClick={() => {
                                    setSelectedContent(content);
                                    setDeleteModal(true);
                                  }}
                                />
                              </div>
                            </VerticalMenu>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="table-data" colSpan="12">
                          <p className="text-center">No Data Available</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                  {deleteModal && (
                    <ConfirmModal
                      title="Delete content"
                      description={`Are you sure you want to delete ‘‘${selectedContent?.title}”?`}
                      onYes={deleteContent}
                      onClose={() => setDeleteModal(false)}
                    />
                  )}
                </table>
              </div>
            </div>
          )}
          <section className={`mt-1 px-3 rouded-lg bg-white`}>
            <Pagination
              data={contents?.meta}
              perPage={perPage}
              pageNo={currentPage}
              setPageNo={setCurrentPage}
              setPerPage={setPerPage}
            />
          </section>
        </div>
      </div>

      <CreateContent
        openCreateContentModal={openCreateContentModal}
        setOpenCreateContentModal={setOpenCreateContentModal}
        refetch={refetch}
        selectedContent={selectedContent}
        setSelectedContent={setSelectedContent}
      />
      <ViewContentModal
        contentType={selectedContent?.type}
        selectedContent={selectedContent}
        filePath={selectedContent?.filePath}
        openViewContentModal={openViewContentModal}
        setOpenViewContentModal={setOpenViewContentModal}
      />
    </div>
  );
};

export default ContentLibrary;
