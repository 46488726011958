import { useState, useEffect } from "react";
import BadgeIcon from "../../../../../../../assets/Badge.png";
import BadgeIconOrange from "../../../../../../../assets/Bagde (5).png";
import BadgeIconGreen from "../../../../../../../assets/Badge (1).png";
import OverviewCard from "../../../../report-and-analysis/components/OverviewCard";
import Button from "../../../../../../../components/Buttons/Button";
import TransactionHistory from "../components/TransactionHistory";
import CreditUsage from "../components/CreditUsage";
import Invoices from "../components/Invoices";
import { api } from "../../../../../../../api/billing";
import { useQuery } from "react-query";
import Search from "../../../../../../../components/Inputs/Search";
import Pagination from "../../../../../../../components/pagination/Pagination";
import GenerateReportModal from "./components/GenerateReportModal";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import PaymentHistory from "../components/PaymentHistory";
import CreditUsageChart from "./components/CreditUsageChart";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

const currentYear = new Date().getFullYear();

export const generateYears = () => {
  let years = [];
  for (let year = currentYear; year >= 1999; year--) {
    years.push(year);
  }
  return years;
};

const Summary = () => {
  const [year, setYear] = useState(currentYear);
  const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [keyword, setKeyword] = useState(null);
  const [cohortId, setCohortId] = useState(null)
  const [learningPathId, setLearningPathId] = useState(null)
  const [courseId, setCourseId] = useState(null)
 
  const user = JSON.parse(sessionStorage.getItem("user"));

  const {
    data: invoices,
    isLoading,
    refetch,
  } = useQuery(
    ["client invoices"],
    ({ queryKey }) => {
      return api.getClientInvoices({
        ...queryKey[1],
        org_id: user?.org?.id,
        year,
      });
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data?.data,
    }
  );


  

  const {
    data: transactionHistory,
    transactionHistoryLoading,
    refetch: refetchTransactionHistory,
  } = useQuery(
    ["transaction history", { id: user?.org?.id, search, perPage, currentPage, cohortId, courseId, learningPathId }],
    ({ queryKey }) => {
      return api.getClientTransactions(queryKey[1]);
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data,
    }
  );


  const {
    data: summaryData, 
    isLoading: summaryDataLoading,    
  } = useQuery(
    ["summarry-data"],
    async () => {
      const response = await api.getClientSummary(user?.org?.id);
        return response.data;
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
    },
    {
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );

  useEffect(() => {
    refetch();
  }, [year, isLoading]);

  useEffect(() => {
    refetchTransactionHistory();
  }, [search, currentPage, perPage]);

  const filterByKeyWord = (value) => {
    setKeyword(value);
  };

  return (
    <div className=" p-4">
      <header className="text-lg font-semibold flex justify-between items-center">
        <div>
          Billing
          <span className="text-gray-500">/ Summary</span>
        </div>
        <div className="flex items-center">
          <div className="mr-3">
            <DateRangePicker
              onChange={setDateRange}
              format="yyyy-mm-dd"
              value={dateRange}
              className={"text-gray-700 w-full bg-white text-xs"}
            />
          </div>
          <Link to={"/admin/billing/top-up"}>
          <Button  text={"Top Up"} bgColor={"bg-darkBlue"} width={"w-[100px] mr-3"} />
          </Link>
          <Button
            text={"Generate Report"}
            width={"w-[160px]"}
            bgColor={"bg-darkBlue"}
            onClick={() => setOpenModal(true)}
          />
          <GenerateReportModal
            openModal={openModal}
            setOpenModal={setOpenModal}
          />
        </div>
      </header>
      <header className="grid pt-3 grid-cols-1 md:grid-cols-4 gap-5">
        <OverviewCard
          title={"Opening Credit"}
          total={summaryDataLoading ? "..." : `$${summaryData?.openingCreditBalance.toLocaleString()}`}
          headerTextSize="text-3xl"
          iconPath={BadgeIconGreen}
        />
        <OverviewCard
          title={"Credit Use"}
          total={summaryDataLoading ? "..." : summaryData?.usedCredit.toLocaleString()}
          iconPath={BadgeIconOrange}
          headerTextSize="text-3xl"
        />
        <OverviewCard
          title={"Closing Balance"}
          headerTextSize="text-3xl"
          total={summaryDataLoading ? "..." : `$${summaryData?.closingCreditBalance.toLocaleString()}`}
          iconPath={BadgeIcon}
        />
        <OverviewCard
          title={"Payments"}
          headerTextSize="text-3xl"
          total={summaryDataLoading ? "..." : summaryData?.numberOfPayments.toLocaleString()}
          iconPath={BadgeIcon}
        />
      </header>
      <section className="mt-5 justify-between">
        {/* <div className='p-3 bg-white rounded-lg'>
                <h5 className='pb-2 flex items-center justify-between border-b border-gray-200 text-md'>
                    Credit Usage
                    <div className='w-[200px]'>
                        <select className='input-md'>
                            <option value="">All Cohorts</option>
                        </select>
                    </div>
                </h5>
                <div className='w-full'>
                  <CreditUsage />
                </div>
            </div> */}
        <div className="flex mt-5 gap-x-6">
          <div className="lg:w-2/3 p-3 bg-white rounded-lg border border-gray-200 shadow">
            <h5 className="pb-2 flex justify-between border-b border-gray-200 text-[#0C0D0F] text-md font-bold">
              Invoice
              <div className="w-[200px] flex items-center">
                <select
                  className="input-md"
                  onChange={(e) => setYear(parseInt(e.target.value))}
                >
                  {generateYears().map((year) => {
                    return (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    );
                  })}
                </select>
                {/* <button className="bi ml-2 bg-primary text-white py-1 rounded-lg px-2 bi-funnel text-lg" /> */}
              </div>
            </h5>
            <Invoices invoices={invoices?.invoices} isLoading={isLoading} />
          </div>
          <div className="lg:w-1/3 flex p-3 bg-white rounded-lg border border-gray-200 shadow">
            <CreditUsageChart />
          </div>
        </div>
       <div className="flex mt-5 gap-x-6">
       <div className=" lg:w-2/3 p-3  bg-white rounded-lg">
          <div className="mb-6 flex justify-between items-center">
            <h5 className="pb-2  border-b border-gray-200 text-md text-[#0C0D0F] font-bold">
              Billing Transaction
              {/* Transaction History */}
            </h5>
            {/* <div className="flex  items-center gap-6">
              <button
                onClick={() => filterByKeyWord("cohort")}
                className={`px-4 py-2 text-sm rounded text-white bg-[#C2C6CE] border border-[#EAEAEA] hover:bg-[#EAEAEA] focus:bg-customBlue ${
                  keyword === "cohort" ? "bg-customBlue" : ""
                }`}
              >
                Cohort
              </button>
              <button
                onClick={() => filterByKeyWord("learning-path")}
                className={`px-4 py-2 text-sm rounded text-white bg-[#C2C6CE] border border-[#EAEAEA] hover:bg-[#EAEAEA] focus:bg-customBlue ${
                  keyword === "learning-path" ? "bg-customBlue" : ""
                }`}
              >
                Learning Path
              </button>
              <button
                onClick={() => filterByKeyWord("course")}
                className={`px-4 py-2 text-sm rounded text-white bg-[#C2C6CE] border border-[#EAEAEA] hover:bg-[#EAEAEA] focus:bg-customBlue ${
                  keyword === "course" ? "bg-customBlue" : ""
                }`}
              >
                Course
              </button>
            </div> */}
          </div>
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-x-6">
              <Search
                onChange={setSearch}
                placeholder={"Search billing transactions..."}
                className={"w-[300px]"}
              />
              {/* <div className="f">filter</div> */}
            </div>
            {/* <div className="flex items-center gap-x-6">
              <p className="">Credit Used</p>
              <p className="text-2xl font-semibold text-[#1D1F2C]">25</p>
            </div>
            <div className="flex flex-col">
              Download
              <span className="bi bi-file-earmark-pdf ml-4 text-lg"></span>
            </div> */}
          </div>
          <TransactionHistory
            isLoading={transactionHistoryLoading}
            transactions={transactionHistory}
            perPage={perPage}
          />
          <section className={`mt-1 px-3 rouded-lg bg-white`}>
            {transactionHistory?.data?.length ? (
              <Pagination
                data={transactionHistory?.meta}
                perPage={perPage}
                pageNo={currentPage}
                setPageNo={setCurrentPage}
                setPerPage={setPerPage}
              />
            ) : null}
          </section>
        </div>
        <div className="lg:w-1/3  p-3 bg-white rounded-lg border border-gray-200 shadow">
            <PaymentHistory />
        </div>
       </div>
      </section>
    </div>
  );
};

export default Summary;
