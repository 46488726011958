import React from "react";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { learners as api } from "../../../../../../api/learners";
import { useQuery } from "react-query";
import LearningPathCard from "./LearningPathCard";
import { ScaleLoader } from "react-spinners";

const CurrentCohort = () => {
  const activeCohort = JSON.parse(sessionStorage.getItem("active-cohort"));
  const { id } = useParams();

  const { data: currentCohort, isLoading } = useQuery(
    ["currentCohort-data"],
    async () => {
      const response = await api.getLearnerCurrentCohortInfo({
        learnerId: id,
        cohortId: activeCohort?.cohort?.id,
      });
      return response.data;
    },
    {
      staleTime: 5 * 60 * 1000, // data is considered fresh for 5 minutes
      cacheTime: 30 * 60 * 1000,
    },
    {
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );

  return (
    <div>
      {isLoading ? (
          <div className="mt-10 flex justify-center items-center">
            <ScaleLoader color="#FF9100" />
          </div>
        ) : (
      <div className="p-8 bg-gray-100 min-h-screen">
        <h1 className="text-2xl font-semibold mb-6">Current Cohort</h1>
        {currentCohort?.learningPaths?.map((path) => (
          <LearningPathCard key={path.journeyId} path={path} />
        ))}
        
      </div>
       )}
    </div>
  );
};

export default CurrentCohort;
